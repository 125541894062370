import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 {...{
      "id": "welcome-to-the-power-stack-contributing-guide"
    }}><a parentName="h2" {...{
        "href": "#welcome-to-the-power-stack-contributing-guide",
        "aria-hidden": true,
        "className": "anchor"
      }}><svg aria-hidden="true" height="1em" width="1em" viewBox="0 0 16 16"><path fillRule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg></a>{`Welcome to the Power Stack contributing guide`}</h2>
    <p>{`Thank you for investing your time in contributing to our project!`}</p>
    <p>{`Read our `}<a parentName="p" {...{
        "href": "/code-of-conduct/"
      }}>{`Code of Conduct`}</a>{` to keep our community approachable and respectable.`}</p>
    <h2 {...{
      "id": "community"
    }}><a parentName="h2" {...{
        "href": "#community",
        "aria-hidden": true,
        "className": "anchor"
      }}><svg aria-hidden="true" height="1em" width="1em" viewBox="0 0 16 16"><path fillRule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg></a>{`Community`}</h2>
    <p>{`We have a community on Slack please join us
`}<a parentName="p" {...{
        "href": "https://join.slack.com/t/power-stack/shared_invite/zt-1hxrlg54k-Nx7wfMApTcK155FVyzz7jQ"
      }}>{`here`}</a>{`.  Alternatively, you can
get involved on our repo's `}<a parentName="p" {...{
        "href": "https://github.com/powerstackdev/power-stack/discussions"
      }}>{`GitHub Discussions`}</a>{`.`}</p>
    <h2 {...{
      "id": "contributor-types"
    }}><a parentName="h2" {...{
        "href": "#contributor-types",
        "aria-hidden": true,
        "className": "anchor"
      }}><svg aria-hidden="true" height="1em" width="1em" viewBox="0 0 16 16"><path fillRule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg></a>{`Contributor types`}</h2>
    <p>{`We welcome contributions from our existing public members and new contributors.`}</p>
    <p>{`Please find the relevant guides below:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#new-contributor-guide"
        }}>{`New contributor guide`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#public-members-contributor-guide"
        }}>{`Public members contributor guide`}</a></li>
    </ul>
    <h2 {...{
      "id": "new-contributor-guide"
    }}><a parentName="h2" {...{
        "href": "#new-contributor-guide",
        "aria-hidden": true,
        "className": "anchor"
      }}><svg aria-hidden="true" height="1em" width="1em" viewBox="0 0 16 16"><path fillRule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg></a>{`New contributor guide`}</h2>
    <p>{`Here are some resources to help you get started with open source contributions:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://docs.github.com/en/get-started/exploring-projects-on-github/finding-ways-to-contribute-to-open-source-on-github"
        }}>{`Finding ways to contribute to open source on GitHub`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://docs.github.com/en/get-started/quickstart/set-up-git"
        }}>{`Set up Git`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://docs.github.com/en/get-started/quickstart/github-flow"
        }}>{`GitHub flow`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://docs.github.com/en/get-started/quickstart/contributing-to-projects"
        }}>{`Contributing to projects`}</a></li>
    </ul>
    <h3 {...{
      "id": "getting-started"
    }}><a parentName="h3" {...{
        "href": "#getting-started",
        "aria-hidden": true,
        "className": "anchor"
      }}><svg aria-hidden="true" height="1em" width="1em" viewBox="0 0 16 16"><path fillRule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg></a>{`Getting started`}</h3>
    <p>{`Here are the important steps to help you get started with contributing to Power Stack:`}</p>
    <h4 {...{
      "id": "forking-the-main-repository"
    }}><a parentName="h4" {...{
        "href": "#forking-the-main-repository",
        "aria-hidden": true,
        "className": "anchor"
      }}><svg aria-hidden="true" height="1em" width="1em" viewBox="0 0 16 16"><path fillRule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg></a>{`Forking the main repository`}</h4>
    <ol>
      <li parentName="ol">{`To contribute without being a public member you can fork the `}<a parentName="li" {...{
          "href": "https://github.com/powerstackdev/power-stack"
        }}>{`project repository`}</a>{`, see the `}<a parentName="li" {...{
          "href": "https://docs.github.com/en/get-started/quickstart/fork-a-repo#forking-a-repository"
        }}>{`Forking a repository`}</a>{` reference`}</li>
      <li parentName="ol">{`Clone your `}<a parentName="li" {...{
          "href": "https://docs.github.com/en/get-started/quickstart/fork-a-repo#cloning-your-forked-repository"
        }}>{`forked repository`}</a>{` into your projects folder`}</li>
    </ol>
    <h4 {...{
      "id": "create-a-new-environment-based-on-your-forked-repository"
    }}><a parentName="h4" {...{
        "href": "#create-a-new-environment-based-on-your-forked-repository",
        "aria-hidden": true,
        "className": "anchor"
      }}><svg aria-hidden="true" height="1em" width="1em" viewBox="0 0 16 16"><path fillRule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg></a>{`Create a new environment based on your forked repository`}</h4>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">
        <p parentName="li">{`Run the `}<strong parentName="p">{`Install Power Stack`}</strong>{` command from the `}<a parentName="p" {...{
            "href": "https://powerstack.dev/docs/intro/getting-started/#install-power-stack"
          }}>{`Getting started documentation`}</a>{` and replace `}<strong parentName="p">{`/powerstackdev`}</strong>{` with your GitHub username`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Once it has installed correctly, you will see a dashboard load in the browser (or a message in the Terminal)`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Login by clicking `}<strong parentName="p">{`Click here to login`}</strong>{` and use the username and password found in the message in the Terminal`}</p>
      </li>
    </ol>
    <h4 {...{
      "id": "solve-an-issue"
    }}><a parentName="h4" {...{
        "href": "#solve-an-issue",
        "aria-hidden": true,
        "className": "anchor"
      }}><svg aria-hidden="true" height="1em" width="1em" viewBox="0 0 16 16"><path fillRule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg></a>{`Solve an issue`}</h4>
    <ol {...{
      "start": 6
    }}>
      <li parentName="ol">{`Go ahead and make your contributions to the project`}</li>
    </ol>
    <h4 {...{
      "id": "commit-your-update"
    }}><a parentName="h4" {...{
        "href": "#commit-your-update",
        "aria-hidden": true,
        "className": "anchor"
      }}><svg aria-hidden="true" height="1em" width="1em" viewBox="0 0 16 16"><path fillRule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg></a>{`Commit your update`}</h4>
    <ol {...{
      "start": 7
    }}>
      <li parentName="ol">{`When you're ready to submit your changes, stage and commit your changes. See the `}<a parentName="li" {...{
          "href": "https://docs.github.com/en/get-started/quickstart/contributing-to-projects#making-and-pushing-changes"
        }}>{`Making and pushing changes`}</a>{` reference`}</li>
    </ol>
    <h4 {...{
      "id": "create-a-pull-request"
    }}><a parentName="h4" {...{
        "href": "#create-a-pull-request",
        "aria-hidden": true,
        "className": "anchor"
      }}><svg aria-hidden="true" height="1em" width="1em" viewBox="0 0 16 16"><path fillRule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg></a>{`Create a pull request`}</h4>
    <ol {...{
      "start": 8
    }}>
      <li parentName="ol">
        <p parentName="li">{`Head over to your forked GitGub repository and locate `}<strong parentName="p">{`Contribute`}</strong>{` dropdown`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Click `}<strong parentName="p">{`Open pull request`}</strong></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`GitHub will bring you to a page where you can enter a title and a description. See the `}<a parentName="p" {...{
            "href": "https://docs.github.com/en/get-started/quickstart/contributing-to-projects#making-a-pull-request"
          }}>{`Make a pull request`}</a>{` reference`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Click `}<strong parentName="p">{`Create pull request`}</strong></p>
      </li>
    </ol>
    <h4 {...{
      "id": "managing-feedback"
    }}><a parentName="h4" {...{
        "href": "#managing-feedback",
        "aria-hidden": true,
        "className": "anchor"
      }}><svg aria-hidden="true" height="1em" width="1em" viewBox="0 0 16 16"><path fillRule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg></a>{`Managing feedback`}</h4>
    <ol {...{
      "start": 12
    }}>
      <li parentName="ol">{`Pull requests will be reviewed before merged by a verified owner`}</li>
    </ol>
    <h2 {...{
      "id": "public-members-contributor-guide"
    }}><a parentName="h2" {...{
        "href": "#public-members-contributor-guide",
        "aria-hidden": true,
        "className": "anchor"
      }}><svg aria-hidden="true" height="1em" width="1em" viewBox="0 0 16 16"><path fillRule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg></a>{`Public members contributor guide`}</h2>
    <p>{`This section assumes you are already part of the Power Stack organisation on `}<a parentName="p" {...{
        "href": "https://github.com/powerstackdev/power-stack"
      }}>{`GitHub`}</a></p>
    <h4 {...{
      "id": "create-a-new-environment"
    }}><a parentName="h4" {...{
        "href": "#create-a-new-environment",
        "aria-hidden": true,
        "className": "anchor"
      }}><svg aria-hidden="true" height="1em" width="1em" viewBox="0 0 16 16"><path fillRule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg></a>{`Create a new environment`}</h4>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Run the `}<strong parentName="p">{`Install Power Stack`}</strong>{` command from the `}<a parentName="p" {...{
            "href": "https://powerstack.dev/docs/intro/getting-started/#install-power-stack"
          }}>{`Getting started documentation`}</a></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Once it has installed correctly, you will see a dashboard load in the browser (or a message in the Terminal)`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Login by clicking `}<strong parentName="p">{`Click here to login`}</strong>{` and use the username and password found in the message in the Terminal`}</p>
      </li>
    </ol>
    <h4 {...{
      "id": "create-a-new-issue"
    }}><a parentName="h4" {...{
        "href": "#create-a-new-issue",
        "aria-hidden": true,
        "className": "anchor"
      }}><svg aria-hidden="true" height="1em" width="1em" viewBox="0 0 16 16"><path fillRule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg></a>{`Create a new issue`}</h4>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">
        <p parentName="li">{`Navigate to `}<a parentName="p" {...{
            "href": "https://github.com/powerstackdev/power-stack/issues"
          }}>{`Issues`}</a>{` and click `}<strong parentName="p">{`New issue`}</strong></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Provide information and assign labels if required`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Under `}<strong parentName="p">{`Development`}</strong>{` create a new branch for this issue`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`The branch information will automatically be added by GitHub`}</p>
      </li>
    </ol>
    <h4 {...{
      "id": "solve-the-issue"
    }}><a parentName="h4" {...{
        "href": "#solve-the-issue",
        "aria-hidden": true,
        "className": "anchor"
      }}><svg aria-hidden="true" height="1em" width="1em" viewBox="0 0 16 16"><path fillRule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg></a>{`Solve the issue`}</h4>
    <ol {...{
      "start": 8
    }}>
      <li parentName="ol">{`Go ahead and make your contributions to the project`}</li>
    </ol>
    <h4 {...{
      "id": "commit-your-update-1"
    }}><a parentName="h4" {...{
        "href": "#commit-your-update-1",
        "aria-hidden": true,
        "className": "anchor"
      }}><svg aria-hidden="true" height="1em" width="1em" viewBox="0 0 16 16"><path fillRule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg></a>{`Commit your update`}</h4>
    <ol {...{
      "start": 9
    }}>
      <li parentName="ol">{`When you're ready to submit your changes, stage and commit your changes. See the `}<a parentName="li" {...{
          "href": "https://docs.github.com/en/get-started/quickstart/contributing-to-projects#making-and-pushing-changes"
        }}>{`Making and pushing changes`}</a>{` reference`}</li>
    </ol>
    <h4 {...{
      "id": "create-a-pull-request-1"
    }}><a parentName="h4" {...{
        "href": "#create-a-pull-request-1",
        "aria-hidden": true,
        "className": "anchor"
      }}><svg aria-hidden="true" height="1em" width="1em" viewBox="0 0 16 16"><path fillRule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg></a>{`Create a pull request`}</h4>
    <ol {...{
      "start": 10
    }}>
      <li parentName="ol">
        <p parentName="li">{`Head over to the main GitGub repository and locate `}<strong parentName="p">{`Pull requests`}</strong></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Click on `}<strong parentName="p">{`New pull request`}</strong></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`GitHub will bring you to a page where you can enter a title and a description. See the `}<a parentName="p" {...{
            "href": "https://docs.github.com/en/get-started/quickstart/contributing-to-projects#making-a-pull-request"
          }}>{`Make a pull request`}</a>{` reference`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Click `}<strong parentName="p">{`Create pull request`}</strong></p>
      </li>
    </ol>
    <h4 {...{
      "id": "managing-feedback-1"
    }}><a parentName="h4" {...{
        "href": "#managing-feedback-1",
        "aria-hidden": true,
        "className": "anchor"
      }}><svg aria-hidden="true" height="1em" width="1em" viewBox="0 0 16 16"><path fillRule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg></a>{`Managing feedback`}</h4>
    <ol {...{
      "start": 13
    }}>
      <li parentName="ol">{`Pull requests will be reviewed before merged by a another member`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      