import { Article, ScreenContainer, HeroSection, Hero, HeroBody, HeroTitle, HeroTeaser, HeroAction, Button, HeroActionList, FeatureSection, FeatureList, Feature, FeatureTitle, FeatureText } from "smooth-doc/components";
import { x } from '@xstyled/styled-components';
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css';
import { FiDatabase, FiLayout, FiLock, FiPenTool } from 'react-icons/fi';
import GettingStarted from "../../../../partials/getting-started.mdx";
import * as React from 'react';
export default {
  Article,
  ScreenContainer,
  HeroSection,
  Hero,
  HeroBody,
  HeroTitle,
  HeroTeaser,
  HeroAction,
  Button,
  HeroActionList,
  FeatureSection,
  FeatureList,
  Feature,
  FeatureTitle,
  FeatureText,
  x,
  LiteYouTubeEmbed,
  FiDatabase,
  FiLayout,
  FiLock,
  FiPenTool,
  GettingStarted,
  React
};