import { ScreenContainer, HeroSection, Hero, HeroBody, HeroTitle, Article } from "smooth-doc/components";
import CodeOfConduct from "../../../../partials/code-of-conduct.mdx";
import * as React from 'react';
export default {
  ScreenContainer,
  HeroSection,
  Hero,
  HeroBody,
  HeroTitle,
  Article,
  CodeOfConduct,
  React
};