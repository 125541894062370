import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 {...{
      "id": "what-is-power-stack"
    }}><a parentName="h2" {...{
        "href": "#what-is-power-stack",
        "aria-hidden": true,
        "className": "anchor"
      }}><svg aria-hidden="true" height="1em" width="1em" viewBox="0 0 16 16"><path fillRule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg></a>{`What is Power Stack?`}</h2>
    <p>{`Power Stack is an open-source digital experience platform (DXP) project.`}</p>
    <p>{`Built on open data and modern web technologies, Power Stack revolutionizes tools for marketing and development teams giving flexibility to put customers at the heart of their digital portfolio.`}</p>
    <h2 {...{
      "id": "try-the-pre-alpha-today"
    }}><a parentName="h2" {...{
        "href": "#try-the-pre-alpha-today",
        "aria-hidden": true,
        "className": "anchor"
      }}><svg aria-hidden="true" height="1em" width="1em" viewBox="0 0 16 16"><path fillRule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg></a>{`Try the pre-alpha today!`}</h2>
    <p>{`Hopefully, if you've read this far, you're convinced and want to try Power Stack.  We've tried to make development and setup as easy as possible using Docksal.`}</p>
    <p>{`Our 1-command install generates a preconfigured ready-to-use project with all the needed dependencies.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      