import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { FiCode, FiCheck } from 'react-icons/fi';
import { ScreenContainer, HeroSection, Hero, HeroBody, HeroTitle, Article } from "smooth-doc/components";
import * as React from 'react';
export default {
  VerticalTimeline,
  VerticalTimelineElement,
  FiCode,
  FiCheck,
  ScreenContainer,
  HeroSection,
  Hero,
  HeroBody,
  HeroTitle,
  Article,
  React
};